import { FC } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';

import logoEfigas from '../public/images/Logo_efigas_full.svg';
import logoGascaribe from '../public/images/Logo_Gases_Two_lines.svg';
import logoGuajira from '../public/images/Logo_gases_guajira_full.svg';

interface ICompanyLogoBarProps {
  responsive?: boolean;
  width?: number;
}

const companyLogo: Record<string, any> = {
  GDC: logoGascaribe,
  EFG: logoEfigas,
  GDG: logoGuajira,
};

const CompanyLogoBar: FC<ICompanyLogoBarProps> = (props) => {
  const router = useRouter();
  const { company } = router.query;
  const { width, responsive } = props;

  return (
    <Image
      src={companyLogo[company as string]}
      alt={company as string}
      width={responsive ? 100 : width || 230}
      height={80}
    />
  );
};

export default CompanyLogoBar;
