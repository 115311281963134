import { atom } from 'recoil';

export interface User {
  id: string;
  email: string;
  name: string;

  roles: string[];
  appMeta: {
    config: Record<string, any>;
  };
  actions?: {
    logout?: () => Promise<void>;
  };
}

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
}

export const authState = atom<AuthState>({
  key: 'authState',
  default: {
    isAuthenticated: false,
    user: null,
  },
});
